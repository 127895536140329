import React, { useEffect, Suspense, lazy } from "react";
import { Box, CssBaseline } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import BackToTop from "./components/BackToTopButton";
import ResponsiveAppBar from "./components/Nav/Nav";
import Loading from "./components/Loading";
import { pages } from "./components/pages";
import Footer from "./components/Footer";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
const Impressum = lazy(() => import("./pages/Impressum"));

let theme = createTheme();
theme = responsiveFontSizes(theme);

theme = createTheme(theme, {
  palette: {
    text: {
      secondary: '#ff4c4c',
      contrastText: '#fff',
    },
    primary: {
      main: '#ff3131',
      light: '#ff4c42',
      dark: '#ff4c62',
      contrastText: '#fff',
    },
  },
});

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      offset: 120,
      duration: 1000,
      useClassNames: false,
      throttleDelay: 99,
      once: true,
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        display: "flex", justifyContent: "space-between", flexDirection: "column"
      }} className="App">
        {
          location.pathname === "/" && (<ResponsiveAppBar pages={pages} />)

        }
        <div>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Főoldal route */}
              <Route
                path="/"
                element={
                  <Box>
                    {pages.map((item) => (
                      <Box key={item.name} id={item.path} className="section">
                        {item.component}
                      </Box>
                    ))}
                  </Box>
                }
              />
              {/* Impressum oldal lazy loadinggal */}
              <Route path="impressum" element={<Impressum />} />

              {/* Ismeretlen route-ok visszairányítása a főoldalra */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <BackToTop />
          </Suspense>
        </div>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
